import { Categories, Category, CategoryIcon, CategoryLabel } from "./index.styled";

const CategoriesList = () => {
    return (
        <Categories>
            <Category>
                <CategoryIcon>
                    <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                        <path d="M8.95131 1C4.54054 1 0.951965 3.95628 0.951965 7.59007C0.965566 8.46362 1.17034 9.32358 1.55189 10.1095C1.93344 10.8954 2.4825 11.5883 3.16048 12.1393L1.56061 15.33C1.5282 15.3946 1.51672 15.4677 1.52777 15.5391C1.53882 15.6105 1.57185 15.6767 1.62226 15.7285C1.67267 15.7803 1.73797 15.8151 1.80908 15.8281C1.88018 15.841 1.95356 15.8315 2.01901 15.8009L6.29623 13.8101C7.1594 14.058 8.05325 14.1828 8.95131 14.1808C13.3621 14.1808 16.9507 11.2246 16.9507 7.59007C16.9507 3.95559 13.3621 1 8.95131 1Z" fill="currentColor"></path>
                    </svg>
                </CategoryIcon>
                <CategoryLabel>To Answer</CategoryLabel>
            </Category>
            <Category active>
                <CategoryIcon active>
                    <svg width="14" height="17" viewBox="0 0 14 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 11.7V1.3C14 0.8 13.7 0.5 13.2 0.5H3C1.3 0.5 0 1.8 0 3.5V13.5C0 15.2 1.3 16.5 3 16.5H13.2C13.6 16.5 14 16.2 14 15.7V15.2C14 15 13.9 14.8 13.7 14.6C13.6 14.1 13.6 12.7 13.7 12.3C13.9 12.2 14 12 14 11.7ZM7.5 2.5C7.89556 2.5 8.28224 2.6173 8.61114 2.83706C8.94004 3.05682 9.19638 3.36918 9.34776 3.73463C9.49913 4.10009 9.53874 4.50222 9.46157 4.89018C9.3844 5.27814 9.19392 5.63451 8.91421 5.91421C8.63451 6.19392 8.27814 6.3844 7.89018 6.46157C7.50222 6.53874 7.10009 6.49913 6.73463 6.34776C6.36918 6.19638 6.05682 5.94004 5.83706 5.61114C5.6173 5.28224 5.5 4.89556 5.5 4.5C5.5 3.96957 5.71071 3.46086 6.08579 3.08579C6.46086 2.71071 6.96957 2.5 7.5 2.5ZM4 9.3C4 8.30594 4.94031 7.5 6.1 7.5H6.25625C6.65069 7.66502 7.07399 7.75 7.50156 7.75C7.92913 7.75 8.35244 7.66502 8.74687 7.5H8.90156C10.0613 7.5 11.0016 8.30594 11.0016 9.3V9.9C11.0016 10.2316 10.6891 10.5 10.3016 10.5H4.7C4.3125 10.5 4 10.2312 4 9.9V9.3ZM11.9 14.5H3C2.4 14.5 2 14.1 2 13.5C2 12.9 2.5 12.5 3 12.5H11.9V14.5Z" fill="currentColor"></path>
                    </svg>
                </CategoryIcon>
                <CategoryLabel active>To Contact</CategoryLabel>
            </Category>
            <Category>
                <CategoryIcon>
                    <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                        <path d="M7.1662 11.3113C7.1662 8.75 9.58486 6.66667 12.5582 6.66667C14.0071 6.64971 15.41 7.17498 16.4915 8.13933C16.5125 8.15896 16.5382 8.17274 16.5662 8.17929C16.5942 8.18585 16.6234 8.18494 16.6509 8.17667C16.6785 8.16866 16.7035 8.15364 16.7236 8.13309C16.7437 8.11253 16.7582 8.08712 16.7655 8.05933C16.8864 7.60492 16.9485 7.13688 16.9502 6.66667C16.9502 2.99067 13.3615 0 8.9502 0C4.53886 0 0.950195 2.99067 0.950195 6.66667C0.961811 7.46182 1.13073 8.24681 1.44722 8.97636C1.76371 9.70591 2.2215 10.3656 2.7942 10.9173L1.8482 14.0713C1.83006 14.1323 1.82976 14.1972 1.84733 14.2584C1.8649 14.3195 1.8996 14.3744 1.94735 14.4164C1.99509 14.4585 2.05387 14.486 2.11675 14.4958C2.17963 14.5055 2.24398 14.497 2.3022 14.4713L5.9502 12.8473C6.40486 12.9996 6.87103 13.1151 7.3442 13.1927C7.37393 13.1975 7.40442 13.1942 7.43245 13.1832C7.46047 13.1721 7.485 13.1537 7.50343 13.1299C7.52187 13.1061 7.53353 13.0777 7.53718 13.0478C7.54084 13.0179 7.53635 12.9876 7.5242 12.96C7.28992 12.4418 7.16791 11.88 7.1662 11.3113ZM7.28353 8.16667H4.9502C4.81759 8.16667 4.69041 8.11399 4.59664 8.02022C4.50287 7.92645 4.4502 7.79927 4.4502 7.66667C4.4502 7.53406 4.50287 7.40688 4.59664 7.31311C4.69041 7.21935 4.81759 7.16667 4.9502 7.16667H7.28353C7.41614 7.16667 7.54331 7.21935 7.63708 7.31311C7.73085 7.40688 7.78353 7.53406 7.78353 7.66667C7.78353 7.79927 7.73085 7.92645 7.63708 8.02022C7.54331 8.11399 7.41614 8.16667 7.28353 8.16667ZM11.9502 5.16667H4.9502C4.81759 5.16667 4.69041 5.11399 4.59664 5.02022C4.50287 4.92645 4.4502 4.79927 4.4502 4.66667C4.4502 4.53406 4.50287 4.40688 4.59664 4.31311C4.69041 4.21934 4.81759 4.16667 4.9502 4.16667H11.9502C12.0828 4.16667 12.21 4.21934 12.3037 4.31311C12.3975 4.40688 12.4502 4.53406 12.4502 4.66667C12.4502 4.79927 12.3975 4.92645 12.3037 5.02022C12.21 5.11399 12.0828 5.16667 11.9502 5.16667Z" fill="currentColor"></path>
                        <path d="M12.558 7.66666C10.136 7.66666 8.16602 9.30132 8.16602 11.3113C8.16602 13.6587 10.8847 15.526 13.8033 14.7993L16.1367 15.9647C16.1948 15.9933 16.2601 16.0043 16.3244 15.9964C16.3887 15.9885 16.4493 15.9621 16.4988 15.9202C16.5484 15.8784 16.5846 15.8231 16.6032 15.761C16.6217 15.6989 16.6218 15.6328 16.6033 15.5707L16.0033 13.5707C16.5996 12.9685 16.9389 12.1586 16.95 11.3113C16.95 9.30132 14.98 7.66666 12.558 7.66666Z" fill="currentColor"></path>
                    </svg>
                </CategoryIcon>
                <CategoryLabel>Open</CategoryLabel>
            </Category>
            <Category>
                <CategoryIcon>
                    <svg viewBox="0 0 16 16" width="16" height="16" fill="currentColor">
                        <path d="M11.6667 6.33333C12.6017 6.33318 13.52 6.58093 14.328 7.05133C14.3509 7.06469 14.3766 7.07239 14.403 7.07378C14.4295 7.07517 14.4559 7.07022 14.48 7.05933C14.5042 7.04824 14.5255 7.03159 14.542 7.01074C14.5586 6.98989 14.57 6.96542 14.5753 6.93933C14.6357 6.62986 14.6663 6.31531 14.6667 6C14.6667 2.692 11.3767 0 7.33333 0C3.29 0 0 2.692 0 6C0.0123656 6.73712 0.175963 7.46385 0.480656 8.13516C0.78535 8.80647 1.22465 9.40806 1.77133 9.90267L0.686667 12.886C0.664638 12.9468 0.660778 13.0127 0.675557 13.0757C0.690335 13.1387 0.723114 13.196 0.769896 13.2406C0.816679 13.2853 0.875445 13.3154 0.939031 13.3272C1.00262 13.3391 1.06827 13.3322 1.128 13.3073L5.016 11.688C5.3873 11.7882 5.76478 11.8639 6.146 11.9147C6.16994 11.9178 6.19429 11.9157 6.21732 11.9085C6.24036 11.9012 6.26153 11.889 6.27933 11.8727C6.2978 11.8559 6.3123 11.8353 6.32176 11.8122C6.33121 11.7891 6.3354 11.7642 6.334 11.7393C6.334 11.7147 6.334 11.6893 6.334 11.664C6.33629 10.2506 6.89893 8.89573 7.89856 7.89648C8.89819 6.89722 10.2532 6.3351 11.6667 6.33333Z" fill="currentColor"></path>
                        <path d="M11.6673 7.33337C10.8103 7.33337 9.97246 7.58752 9.25985 8.06367C8.54724 8.53983 7.99182 9.2166 7.66384 10.0084C7.33586 10.8002 7.25005 11.6715 7.41725 12.5121C7.58445 13.3527 7.99716 14.1248 8.60319 14.7308C9.20922 15.3369 9.98134 15.7496 10.8219 15.9168C11.6625 16.084 12.5338 15.9982 13.3256 15.6702C14.1174 15.3422 14.7942 14.7868 15.2704 14.0742C15.7465 13.3616 16.0007 12.5238 16.0007 11.6667C15.9992 10.5179 15.5422 9.41649 14.7299 8.60414C13.9175 7.79178 12.8162 7.33479 11.6673 7.33337ZM9.40465 11.7847C9.45109 11.7382 9.50622 11.7014 9.5669 11.6762C9.62759 11.6511 9.69263 11.6381 9.75832 11.6381C9.82401 11.6381 9.88905 11.6511 9.94974 11.6762C10.0104 11.7014 10.0656 11.7382 10.112 11.7847L11.112 12.7847L13.0487 10.202C13.1282 10.096 13.2467 10.0258 13.3779 10.0071C13.5092 9.98831 13.6426 10.0225 13.7487 10.102C13.8547 10.1816 13.9249 10.3001 13.9436 10.4313C13.9624 10.5626 13.9282 10.696 13.8487 10.802L11.912 13.3847C11.8259 13.4994 11.7162 13.5942 11.5903 13.6628C11.4644 13.7314 11.3252 13.7721 11.1822 13.7821C11.0392 13.7922 10.8957 13.7714 10.7614 13.7211C10.6271 13.6708 10.5052 13.5922 10.404 13.4907L9.40399 12.4907C9.31048 12.397 9.25802 12.27 9.25815 12.1376C9.25828 12.0052 9.31097 11.8783 9.40465 11.7847Z" fill="currentColor"></path>
                    </svg>
                </CategoryIcon>
                <CategoryLabel>Closed</CategoryLabel>
            </Category>
        </Categories>
    )
}

export default CategoriesList;