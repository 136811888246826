export enum WidgetType {
    Standard = "Standard",
    Action = "Action",
    Timeline = "Timeline",
    Sendable = "Sendable",
}

export enum WidgetScope {
    User = "user",
    Conversation = "conversation",
}

export enum WidgetTabs {
    Widget = "Widget",
    Timeline = "Timeline",
}

export enum WidgetState {
    containerDomain = "containerDomain",
    footer = "footer",
    header = "header",
    id = "id",
    isOpen = "isOpen",
    isReady = "isReady",
    isShown = "isShown",
    label = "label",
    maxHeight = "maxHeight",
    size = "size",
    type = "type",
    url = "url",
    widgetDomain = "widgetDomain",
}

export enum WidgetViewSize {
    Large = "Large",
    Medium = "Medium",
    Small = "Small",
}

export interface TWidgetState {
    containerDomain?: string
    footer: string
    header: string
    id: string
    isOpen: boolean
    isReady: boolean
    isShown: boolean
    label: string
    maxHeight: number
    size: WidgetViewSize
    type: WidgetType
    url: string
    widgetDomain: string
}

const ContainerEvents = {
    activeConversationChanged: "active_conversation_changed",
};

const WidgetEvents = {
    postForwardExample: "post_forward_example",
    actionCreationFailed: "action_creation_failed",
    appendTextToMessage: "append_text_to_message",
    replaceTextMessage: "replace_text_message",
};

export const EventNames = {
    widget: WidgetEvents,
    container: ContainerEvents,
};