import React from 'react';
import { useTheme } from 'styled-components';
import EmojiIcon from '../../icons/Emoji';
import PlusIcon from '../../icons/Plus';
import SendIcon from '../../icons/Send';
import IconButton from '../IconButton';
import ContactTab from './ContactTab';
import { ContentArea, Header, MessageBar, StatusWrapper, Wrapper, StatusTitle, StatusLabel, StatusArrow, AddMenu, AddMenuItem } from './index.styled';
import LogTab from './LogTab';
import ManageWidgetTab from './ManageWidgetTab';
import { ContactContext, TContactContextState } from '../../providers/ContactProvider';
import Dropdown from '../Dropdown';
import ArrowDownIcon from '../../icons/ArrowDown';
import { AppContext, TAppContext } from '../../providers/AppContext';
import { SENDABLE_HEADER, STANDARD_TOP_POSITION, TWidgetContextState, WidgetContext } from '../../providers/WidgetProvider';
import { SendableWidget } from '../Widget';
import AutoGrowTextArea from './AutoGrowTextArea';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { LogContext, TLogContextState } from '../../providers/LogProvider';
import { containerClient } from '../../App';
import { EventNames, WidgetState } from '../../types';

const Body = () => {
    const theme = useTheme();
    const ddRef = React.useRef<any>();
    const { setSelectedOption, selectedOption, mainOptions } = React.useContext<TAppContext>(AppContext);
    const { logSentEvent } = React.useContext<TLogContextState>(LogContext);
    const { allWidgets, initialized, sendableWidgets, state, changeProperty, setMaxHeightChanged } = React.useContext<TWidgetContextState>(WidgetContext);
    const { selected } = React.useContext<TContactContextState>(ContactContext);
    const [addOpen, setAddOpen] = React.useState<boolean>(false);
    const [input, setInput] = React.useState<string>('');
    const [bottomPadding, setBottomPadding] = React.useState<number>(49);

    useOnClickOutside(ddRef, () => {
        setAddOpen(false);
    });

    React.useEffect(() => {
        containerClient.on(EventNames.widget.appendTextToMessage, (e) => {
            (input.length > 0) ? 
                setInput(`${input} ${e.payload}`) :
                setInput(e?.payload);
        })
        containerClient.on(EventNames.widget.replaceTextMessage, (e) => {
            setInput(e?.payload);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (initialized && allWidgets.length === 0) {
            setSelectedOption(mainOptions[2]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allWidgets, initialized]);

    React.useEffect(() => {
        const el = document.getElementById('message-bar');
        if (el) {
            const padding = (input.length > 0) ? el.offsetHeight : 48;
            setBottomPadding(padding);
            const found = sendableWidgets.filter(widget => state[widget.id].isOpen)

            if (found.length === 1) {
                const maxHeight = window.innerHeight - SENDABLE_HEADER - STANDARD_TOP_POSITION - padding;
                containerClient.setState(found[0].id, WidgetState.maxHeight, maxHeight)
                logSentEvent([{
                    name: found[0].id,
                    type: WidgetState.maxHeight,
                    value: maxHeight
                }]);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input])

    return (
        <Wrapper style={{ position: 'relative' }}>
            <Header>
                <h2>{selected ? selected.name : `No user selected`}</h2>
                <Dropdown 
                    label="selection"
                    hideLabel={true}
                    selected={selectedOption}
                    options={mainOptions}
                    onChange={(option) => setSelectedOption(option)}
                    renderDisplay={() => (
                        <StatusWrapper className="c-chat-status-option-selected c-chat-status-option-selected__TO_BE_CONTACTED">
                            <StatusTitle className="c-chat-status-option-selected__header c-chat-status-option-selected__header__TO_BE_CONTACTED">
                                Manage playground
                            </StatusTitle>
                            <StatusLabel className="c-chat-status-option-selected__label c-chat-status-option-selected__label__TO_BE_CONTACTED">
                                {selectedOption.label}
                                <StatusArrow className="c-chat-status-option-selected__icon">
                                    <ArrowDownIcon />
                                </StatusArrow>
                            </StatusLabel>
                        </StatusWrapper>
                    )}
                />
            </Header>

            <ContentArea>
                <LogTab visible={selectedOption.value === 'logs'} />
                <ContactTab visible={selectedOption.value === 'addcontact'} />
                <ManageWidgetTab visible={selectedOption.value === 'managewidgets'} />
            </ContentArea>

            {sendableWidgets.map(widget => 
                <SendableWidget 
                    key={widget.id}
                    visible={state[widget.id].isOpen}
                    state={state[widget.id]}
                    bottom={bottomPadding}
                />
            )}

            <MessageBar id="message-bar">
                <IconButton ref={ddRef} size={40} disabled={sendableWidgets.length === 0} onClick={() => setAddOpen(!addOpen)}>
                    {addOpen && 
                        <AddMenu top={sendableWidgets.length * 40}>
                            {sendableWidgets.map(widget => state[widget.id].label.length > 0 ? (
                                <AddMenuItem 
                                    key={widget.id}
                                    onClick={() => {
                                        changeProperty(widget.id, 'isOpen', true);
                                        setAddOpen(false);
                                        setMaxHeightChanged(state[widget.id]);
                                    }}
                                >
                                    {state[widget.id].label}
                                </AddMenuItem>
                            ) : null )}
                        </AddMenu>
                    }
                    <PlusIcon size={14} color={theme.colors.text.l2} />
                </IconButton>
                <IconButton size={40} disabled={true}>
                    <EmojiIcon size={16} color={theme.colors.text.l2} />
                </IconButton>
                <AutoGrowTextArea 
                    value={input}
                    onChange={setInput}
                    placeholder='Type your message'
                />
                <IconButton size={32} disabled={input.length === 0} onClick={() => setInput('')} style={input.length > 0 ? {
                    borderRadius: 999,
                    backgroundColor: theme.colors.primary.default,
                    marginTop: 4, 
                    marginBottom: 4
                } : { marginTop: 4, marginBottom: 4 }}>
                    <SendIcon size={28} color={input.length === 0 ? theme.colors.backgrounds.secondary : theme.colors.text.white} />
                </IconButton>
            </MessageBar>
        </Wrapper>
    )
}

export default Body;