import styled from "styled-components";
import { ACTION_TOP_POSITION, STANDARD_TOP_POSITION } from "../../providers/WidgetProvider";

export const ActionWrapper = styled.div<{ visible: boolean }>`
    display: flex;
    position: absolute;
    flex-grow: 1;
    background: ${p => p.theme.colors.backgrounds.white};
    margin: 0;
    box-sizing: border-box;
    border-radius: 0.375rem;
    width: 379px;
    border: 1px solid ${p => p.theme.colors.backgrounds.spacer};
    overflow: hidden;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 10;
    top: ${ACTION_TOP_POSITION}px;
    right: ${p => !p.visible ? '-9999px' : '0'};
    bottom: 0;
    box-shadow: 0px -2px 8px rgb(39 40 51 / 12%);
`

export const SendableWrapper = styled.div<{ visible: boolean, bottom: number }>`
    display: flex;
    position: absolute;
    flex-grow: 1;
    background: ${p => p.theme.colors.backgrounds.white};
    margin: 0;
    box-sizing: border-box;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    border: 1px solid ${p => p.theme.colors.backgrounds.spacer};
    overflow: hidden;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 10;
    top: ${STANDARD_TOP_POSITION}px;
    left: ${p => !p.visible ? '9999px' : '0'};
    right: ${p => !p.visible ? 'auto' : '0'};
    bottom: ${p => `${p.bottom}px`};
    box-shadow: 0px 0px 8px rgb(39 40 51 / 12%);
`

export const StandardWrapper = styled.div<{ visible: boolean, open: boolean }>`
    display: flex;
    position: ${p => !p.visible ? 'absolute' : p.open ? 'absolute' : 'relative'};
    flex-grow: 1;
    background: ${p => p.theme.colors.backgrounds.white};
    margin: ${p => p.open ? '0' : '8px'};
    box-sizing: border-box;
    border-radius: 0.375rem;
    width: ${p => p.open ? '379px' : '363px'};
    border: ${p => p.open ? '0' : '1px'} solid ${p => p.theme.colors.backgrounds.spacer};
    overflow: hidden;
    flex-direction: column;
    box-sizing: border-box;
    z-index: ${p => p.open ? '10' : '1'};
    top: ${p => p.open ? `${STANDARD_TOP_POSITION}px` : 'auto'};
    right: ${p => !p.visible ? '-9999px' : p.open ? '0' : 'auto'};
    bottom: ${p => p.open ? '0' : 'auto'};
    box-shadow: ${p => p.open ? `0px -2px 8px rgb(39 40 51 / 12%);` : 'none'};
`

export const TimelineWrapper = styled.div<{ visible: boolean }>`
    display: flex;
    position: ${p => !p.visible ? 'absolute' : 'relative'};
    flex-grow: 1;
    background: transparent;
    margin: 0;
    box-sizing: border-box;
    border-radius: 0;
    width: 379px;
    border: 0;
    overflow: hidden;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1;
    top: auto;
    right: ${p => !p.visible ? '-9999px' : 'auto'};
    left: ${p => !p.visible ? 'auto' : '0'};
    bottom: 0;
`

export const WidgetFooter = styled.div`
    height: 40px;
    font-size: ${p => p.theme.fontSize.small};
    font-weight: ${p => p.theme.fontWeight.medium};
    border-top: 1px solid ${p => p.theme.colors.backgrounds.spacer};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${p => p.theme.colors.primary.default};
    cursor: pointer;
    box-sizing: border-box;
`

export const WidgetHeader = styled.div<{ collapsed: boolean }>`
    height: 44px;
    font-size: ${p => p.theme.fontSize.small};
    color: ${p => p.theme.colors.text.default};
    font-weight: ${p => p.theme.fontWeight.bold};
    border-bottom: 1px solid ${p => p.theme.colors.backgrounds.spacer};
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
        display: flex;
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 14px 16px 14px 0;
        padding-left: 14px;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        width: 44px;
        height: 44px;
        transform: ${p => p.collapsed ? 'none' : 'rotate(-90deg)'};
    }
`

export const WidgetFullscreenHeader = styled.div`
    height: 59px;
    font-size: ${p => p.theme.fontSize.small};
    color: ${p => p.theme.colors.text.default};
    font-weight: ${p => p.theme.fontWeight.bold};
    border-bottom: 1px solid ${p => p.theme.colors.backgrounds.spacer};
    display: flex;
    align-items: center;

    button {
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 44px;
        position: absolute;
        background: transparent;
        border: 0;
        font-size: ${p => p.theme.fontSize.small};
        font-weight: ${p => p.theme.fontWeight.medium};
        color: ${p => p.theme.colors.primary.default};
        user-select: none;
        cursor: pointer;

        svg {
            transform: rotate(180deg);
            margin-right: 4px;
        }
    }

    span {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 14px 16px 14px 0;
        padding-left: 14px;
    }
`

export const SendableHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    height: 40px;
    min-height: 40px;
    border-bottom: 1px solid ${p => p.theme.colors.backgrounds.spacer};

    button {
        position: absolute;
        right: 16px;
        display: flex;
        align-items: center;
        jusitfy-content: center;
        padding: 0;
        background: transparent;
        border: 0;
        transform: rotate(-180deg);
        cursor: pointer;

        svg {
            fill: ${p => p.theme.colors.primary.default};
        }
    }

    h2 {
        margin-left: 16px;
        font-size: ${p => p.theme.fontSize.regular};
        font-weight: ${p => p.theme.fontWeight.medium};
        color: ${p => p.theme.colors.text.default};
    }
`

export const WidgetContent = styled.div<{ collapsed: boolean, open: boolean, maxHeight?: number }>`
    display: ${p => p.collapsed ? 'none' : 'block'};
    height: ${p => p.maxHeight ? `${p.maxHeight}px` : `auto`};
    min-height: ${p => p.maxHeight ? `${p.maxHeight}px` : `auto`};
    max-height: ${p => p.maxHeight ? `${p.maxHeight}px` : p.open ? 'none' : '385px'};

    iframe {
        flex: 1;
        width: 100%;
    }
`