import React from "react";
import config from "../utils/config";
import { WidgetMethods, WidgetPostEventType } from "@statflo/widget-sdk/dist/shared";
import { LogContext, TLogContextState } from "./LogProvider";
import { containerClient } from "../App";
import { EventNames, WidgetScope, WidgetState, WidgetType, WidgetViewSize } from "../types";

export interface TWidgetState {
    name: string;
    isReady: boolean;
    type: WidgetType | string;
    size: WidgetViewSize | null;
    url: string;
    isOpen: boolean;
    header: string;
    footer: string;
    label: string;
}

type TWidgetRootState = {
    [key: string]: TWidgetState;
}

export interface TWidgetUrl {
    id: string;
    name: string;
    url: string;
    type: WidgetType | string;
    label?: string;
    order?: string;
    active: boolean;
    scope?: WidgetScope | string;
}

export interface TWidgetContextState {
    addWidget: (widget: TWidgetUrl) => void;
    changeWidgetState: (widget: TWidgetUrl, enabled: boolean) => void;
    removeWidget: (widget: TWidgetUrl) => void;
    registerWidget: (widget: TWidgetUrl) => void;
    unregisterWidget: (id: string) => void;
    changeProperty: (id: string, key: string, value: string | boolean) => void;
    widgetReady: (id: string) => void;
    closeAll: () => void;
    uploadWidgets: (widgets: TWidgetUrl[]) => void;

    contextChanged: (externalId: string) => void;
    viewSizeChanged: (widget: TWidgetState, value: WidgetViewSize) => void;
    setMaxHeightChanged: (widget: TWidgetState) => void;

    allWidgets: TWidgetUrl[];
    defaultWidgets: TWidgetUrl[];
    timelineWidget: TWidgetUrl | undefined | null;
    actionWidgets: TWidgetUrl[];
    plusWidget: TWidgetUrl | undefined | null;
    sendableWidgets: TWidgetUrl[];
    event?: WidgetPostEventType;
    state: TWidgetRootState;
    initialized: boolean;
}

type TWidgetActions = 
    { type: 'REGISTER_WIDGET', payload: TWidgetUrl } | 
    { type: 'UNREGISTER_WIDGET', payload: string } | 
    { type: 'WIDGET_READY', payload: string } | 
    { type: 'CHANGE_SIZE' } | 
    { type: 'CHANGE_PROPERTY', id: string, key: string, value: string | boolean } | 
    { type: 'CLOSE_ALL' };

function reducer(prevState: TWidgetRootState, action: TWidgetActions): TWidgetRootState {
    switch(action.type) {
        case 'REGISTER_WIDGET':
            return {
                ...prevState,
                [action.payload.id]: {
                    name: action.payload.id,
                    isReady: false,
                    url: action.payload.url,
                    type: action.payload.type,
                    size: (action.payload.type === (WidgetType.Standard)) ? WidgetViewSize.Medium : null,
                    isOpen: false,
                    header: "",
                    footer: "",
                    label: "",
                }
            };
        case 'UNREGISTER_WIDGET':
            delete prevState[action.payload];
            return { ...prevState };
        case 'CHANGE_PROPERTY':
            return {
                ...prevState,
                [action.id]: {
                    ...prevState[action.id],
                    [action.key]: action.value,
                }
            };
        case 'CHANGE_SIZE':
            return prevState;
        case 'WIDGET_READY':
            return {
                ...prevState,
                [action.payload]: {
                    ...prevState[action.payload],
                    isReady: true,
                }
            };
        case 'CLOSE_ALL':
            const all = { ...prevState };
            Object.keys(all).forEach(id => {
                if (all[id].type === WidgetType.Action || all[id].type === WidgetType.Standard) {
                    all[id].size = (all[id].type === WidgetType.Action) ? WidgetViewSize.Small : all[id].size;
                    all[id].isOpen = false;
                }
            });
            return all;
        default:
            return prevState;
    }
}

export const WidgetContext = React.createContext<TWidgetContextState>({
    addWidget: () => {},
    changeWidgetState: () => {},
    removeWidget: () => {},
    registerWidget: () => {},
    unregisterWidget: () => {},
    changeProperty: () => {},
    widgetReady: () => {},
    closeAll: () => {},
    uploadWidgets: () => {},

    contextChanged: () => {},
    viewSizeChanged: () => {},
    setMaxHeightChanged: () => {},

    allWidgets: [],
    defaultWidgets: [],
    timelineWidget: null,
    actionWidgets: [],
    plusWidget: null,
    sendableWidgets: [],
    state: {},
    initialized: false,
});

export const ACCOUNT_DETAILS_HEIGHT = 235;
export const ACTIONS_BAR_HEIGHT = 48;
export const STANDARD_HEADER_HEIGHT = 43;
export const SENDABLE_HEADER = 40;
export const STANDARD_TOP_POSITION = 60;
export const ACTION_TOP_POSITION = 25;

export const WidgetProvider = ({ children }: { children: React.ReactNode}) => {
    const { logSentEvent, receivedLogs, setReceivedLogs } = React.useContext<TLogContextState>(LogContext);
    const [allWidgets, setAllWidgets] = React.useState<TWidgetUrl[]>([]);
    const [initialized, setInitialized] = React.useState<boolean>(false);
    const [state, dispatch] = React.useReducer(reducer, {});
    const [event] = React.useState<WidgetPostEventType>();

    // Context Change
    const contextChanged = (externalId: string) => {
        const contextObj = {
            externalId,
            metadata: {}
        };
        
        const logs: any[] = [contextObj];

        allWidgets.forEach(widget => {
            containerClient.post(widget.id, EventNames.container.activeConversationChanged, contextObj);
            if (widget.type === WidgetType.Standard && state[widget.id].size === WidgetViewSize.Large) {
                dispatch({ type: 'CHANGE_PROPERTY', id: widget.id, key: 'size', value: WidgetViewSize.Medium });
                containerClient.setState(widget.id, WidgetState.size, WidgetViewSize.Medium);
                logs.push({
                    type: WidgetState.size,
                    value: WidgetViewSize.Medium
                });
            }
        });

        // Log message sent
        logSentEvent(logs);
    }

    const getMaxHeight = (widget: TWidgetState): number => {
        const height = window.innerHeight;

        let containerHeight: number = 0;

        if (widget.type === WidgetType.Standard) {
            containerHeight = height - STANDARD_TOP_POSITION - STANDARD_HEADER_HEIGHT;
        } else if (widget.type === WidgetType.Action) {
            containerHeight = height - ACTION_TOP_POSITION;
        } else if (widget.type === WidgetType.Timeline) {
            containerHeight = height - ACCOUNT_DETAILS_HEIGHT - ACTIONS_BAR_HEIGHT;
        } else if (widget.type === WidgetType.Sendable) {
            const bar = document.getElementById('message-bar');
            if (bar) {
                containerHeight = height - STANDARD_TOP_POSITION - bar.offsetHeight - SENDABLE_HEADER;
            } else {
                containerHeight = height - STANDARD_TOP_POSITION - SENDABLE_HEADER;
            }
        }

        return containerHeight;
    }

    // MaxHeight Change
    const setMaxHeightChanged = (widget: TWidgetState) => {
        let containerHeight: number = getMaxHeight(widget);

        if (containerHeight !== 0) {
            containerClient.setState(widget.name, WidgetState.maxHeight, containerHeight);
    
            logSentEvent([{
                type: WidgetState.maxHeight,
                value: containerHeight
            }]);
        }
    }

    // View Size Changed
    const viewSizeChanged = (widget: TWidgetState, value: WidgetViewSize) => {
        containerClient.setState(widget.name, WidgetState.size, value);
        logSentEvent([{
            type: WidgetState.size,
            value
        }]);
    }

    const handleResize = () => {
        allWidgets.forEach(widget => {
            setMaxHeightChanged(state[widget.id]);
        });
    }

    React.useEffect(() => {
        const data = localStorage.getItem(config.storage.widgets);

        if (data) {
            const widgets: TWidgetUrl[] = JSON.parse(data);
            setAllWidgets(widgets);
            widgets.forEach(widget => {
                dispatch({ type: 'REGISTER_WIDGET', payload: widget })
            });
        }

        setInitialized(true);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        allWidgets.forEach((widget) => {
            if (widget.active && !containerClient.states.get(widget.id)) {
                const { origin } = new URL(widget.url);
                containerClient.createWidget({
                    ...state[widget.id],
                    id: widget.id,
                    widgetDomain: origin
                });
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    containerClient.on(WidgetMethods.setState, (e: WidgetPostEventType) => {
        const { property, value } = e.payload;
        dispatch({ type: 'CHANGE_PROPERTY', id: e.id, key: property, value });

        setReceivedLogs([
            {
                date: new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date()),
                event: {
                    name: e.id,
                    type: property,
                    value
                }
            },
            ...receivedLogs,
        ]);
    });

    const widgetContext = React.useMemo<TWidgetContextState>(() => ({
        addWidget: (payload) => {
            const list: TWidgetUrl[] = [
                ...allWidgets,
                payload
            ];
            setAllWidgets(list);
            localStorage.setItem(config.storage.widgets, JSON.stringify(list));
            dispatch({ type: 'REGISTER_WIDGET', payload });
        },
        changeWidgetState: (payload, enabled) => {
            const foundWidget = allWidgets.filter(widget => widget.id === payload.id);
            const otherWidgets = allWidgets.filter(widget => widget.id !== payload.id);
            if (foundWidget.length === 1) {
                const list: TWidgetUrl[] = [
                    ...otherWidgets,
                    {
                        ...foundWidget[0],
                        active: enabled
                    }
                ];

                setAllWidgets(list);
                localStorage.setItem(config.storage.widgets, JSON.stringify(list));
            }
        },
        removeWidget: (payload) => {
            const list: TWidgetUrl[] = allWidgets.filter(widget => widget.id !== payload.id);
            setAllWidgets(list);
            localStorage.setItem(config.storage.widgets, JSON.stringify(list));
            dispatch({ type: 'UNREGISTER_WIDGET', payload: payload.id });
        },
        registerWidget: (payload) => {
            dispatch({ type: 'REGISTER_WIDGET', payload });
        },
        unregisterWidget: (payload) => {
            dispatch({ type: 'UNREGISTER_WIDGET', payload });
        },
        changeProperty: (id, key, value) => {
            dispatch({ type: 'CHANGE_PROPERTY', id, key, value });
        },
        widgetReady: (payload) => {
            dispatch({ type: 'WIDGET_READY', payload });
        },
        closeAll: () => {
            dispatch({ type: 'CLOSE_ALL' });
        },
        uploadWidgets: (widgets: TWidgetUrl[]) => {
            localStorage.setItem(config.storage.widgets, JSON.stringify(widgets));
            window.location.reload();
        },

        contextChanged,
        viewSizeChanged,
        setMaxHeightChanged,

        allWidgets,
        defaultWidgets: allWidgets.filter(widget => widget.type === WidgetType.Standard && widget.active),
        timelineWidget: allWidgets.filter(widget => widget.type === WidgetType.Timeline && widget.active)[0],
        actionWidgets: allWidgets.filter(widget => widget.type === WidgetType.Action && widget.active && widget.scope === WidgetScope.Conversation),
        plusWidget: allWidgets.filter(widget => widget.type === WidgetType.Action && widget.active && widget.scope === WidgetScope.User)[0],
        sendableWidgets: allWidgets.filter(widget => widget.type === WidgetType.Sendable && widget.active),
        event,
        state: state,
        initialized,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [state, allWidgets, event]);

    return (
        <WidgetContext.Provider value={widgetContext}>
            {children}
        </WidgetContext.Provider>
    );
}