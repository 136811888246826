import IframeResizer from "iframe-resizer-react";

import { ACCOUNT_DETAILS_HEIGHT, ACTIONS_BAR_HEIGHT, TWidgetState } from "../../providers/WidgetProvider";
import { ActionWrapper, WidgetContent } from "./index.styled";

const ActionWidget = ({ state, visible = false }: { state: TWidgetState, visible: boolean }) => {
    const maxHeight = window.innerHeight - ACCOUNT_DETAILS_HEIGHT - ACTIONS_BAR_HEIGHT;
    
    return (
        <ActionWrapper id={`${state.name}-action-widget-wrapper`} visible={visible}>
            <WidgetContent collapsed={false} open={visible} maxHeight={maxHeight}>
                <IframeResizer
                    name={state.name}
                    title={state.name}
                    id={state.name}
                    frameBorder="0"
                    seamless
                    src={state.url}
                    checkOrigin={false}
                    onMessage={() => {}}
                />
            </WidgetContent>
        </ActionWrapper>
    );
}

export default ActionWidget
