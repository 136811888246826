import * as React from "react"

function UploadIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path d="M11.999 24.001a.75.75 0 01-.75-.75v-7.189l-2.47 2.47a.744.744 0 01-1.06 0 .752.752 0 010-1.061l3.75-3.75a.763.763 0 01.246-.164l.027-.01a.755.755 0 01.518 0l.022.008a.732.732 0 01.249.165l3.749 3.749a.752.752 0 01-.53 1.281.743.743 0 01-.53-.22l-2.47-2.47v7.189a.753.753 0 01-.751.752z" />
            <path d="M17.249 16.501a.75.75 0 010-1.5h1.125a4.133 4.133 0 004.133-4.118 4.13 4.13 0 00-4.118-4.132c-.849 0-1.66.253-2.352.732a.751.751 0 01-1.165-.479 6.752 6.752 0 00-7.88-5.388A6.708 6.708 0 002.666 4.44a6.713 6.713 0 00-1.062 5.056 6.725 6.725 0 005.293 5.37c.196.04.365.153.476.321.111.167.15.367.11.563a.753.753 0 01-.884.586A8.217 8.217 0 01.13 9.774a8.193 8.193 0 011.298-6.179A8.196 8.196 0 016.716.142a8.245 8.245 0 019.38 5.592 5.507 5.507 0 012.27-.484h.026a5.631 5.631 0 015.614 5.635 5.633 5.633 0 01-5.625 5.615h-1.132z" />
        </svg>
    )
}

export default UploadIcon