import React from "react";
import IframeResizer from "iframe-resizer-react";
import { useTheme } from "styled-components";

import { StandardWrapper, WidgetContent, WidgetFooter, WidgetFullscreenHeader, WidgetHeader } from "./index.styled";
import { TWidgetContextState, TWidgetState, WidgetContext } from "../../providers/WidgetProvider";
import ArrowRightIcon from "../../icons/ArrowRight";
import { WidgetViewSize } from "../../types";

const StandardWidget = ({ state, visible = false }: { state: TWidgetState, visible: boolean }) => {
    const theme = useTheme();
    const { changeProperty, viewSizeChanged, setMaxHeightChanged } = React.useContext<TWidgetContextState>(WidgetContext);

    const handleShowMore = () => {
        viewSizeChanged(state, WidgetViewSize.Large);
        changeProperty(state.name, 'size', WidgetViewSize.Large);
        setMaxHeightChanged(state);
    }

    const handleBackButton = () => {
        viewSizeChanged(state, WidgetViewSize.Medium);
        changeProperty(state.name, 'size', WidgetViewSize.Medium);
    }

    return (
        <StandardWrapper id={`${state.name}-standard-widget-wrapper`} visible={visible} open={state.size === WidgetViewSize.Large}>
            {state.header.length > 0 && state.size !== WidgetViewSize.Large && 
                <WidgetHeader collapsed={state.size === WidgetViewSize.Small} onClick={() => {
                    changeProperty(state.name, 'size', state.size === WidgetViewSize.Small ? WidgetViewSize.Medium : WidgetViewSize.Small)
                }}>
                    <span>{state.header}</span>
                    <div>
                        <ArrowRightIcon size={14} color={theme.colors.text.l3} />
                    </div>
                </WidgetHeader>
            }

            {state.header.length > 0 && state.size === WidgetViewSize.Large &&
                <WidgetFullscreenHeader>
                    <button onClick={handleBackButton}>
                        <ArrowRightIcon size={16} color={theme.colors.primary.default} />
                        Back
                    </button>
                    <span>{state.header}</span>
                </WidgetFullscreenHeader>
            }
            <WidgetContent collapsed={state.size === WidgetViewSize.Small} open={state.size === WidgetViewSize.Large}>
                <IframeResizer
                    name={state.name}
                    title={state.name}
                    id={state.name}
                    frameBorder="0"
                    seamless
                    src={state.url}
                    checkOrigin={false}
                    onMessage={() => {}}
                />
            </WidgetContent>
            {state.footer.length > 0 && state.size === WidgetViewSize.Medium &&
                <WidgetFooter onClick={handleShowMore}>
                    {state.footer}
                </WidgetFooter>
            }
        </StandardWrapper>
    );
}

export default StandardWidget
