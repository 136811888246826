function MessageBubbleIcon({ size = 24, color = 'gray'}) {
    return (
        <svg viewBox="0 0 24 24" width={size} height={size} fill={color}>
            <path d="M12 .874C5.406.874.041 5.333.041 10.815a8.981 8.981 0 003.045 6.634L.969 21.675a1 1 0 001.316 1.355l5.957-2.774a14.179 14.179 0 003.758.5c6.594 0 11.959-4.459 11.959-9.941S18.594.874 12 .874zm0 17.882a12.11 12.11 0 01-3.547-.521 1 1 0 00-.715.05L4.692 19.7a.25.25 0 01-.329-.338l.86-1.716a1 1 0 00-.269-1.229 7.186 7.186 0 01-2.913-5.6c0-4.379 4.468-7.941 9.959-7.941s9.959 3.562 9.959 7.941-4.468 7.939-9.959 7.939z" />
            <path d="M7.5 9.374H14a1 1 0 000-2H7.5a1 1 0 000 2zM17 11.874H7.5a1 1 0 000 2H17a1 1 0 000-2z" />
        </svg>
    );
}

export default MessageBubbleIcon;
