const config = {
    storage: {
        widgets: 'textKitWidgets',
        contacts: 'textKitContacts'
    },
    export: {
        widgets: 'TextKit-Playground-Widgets',
        contacts: 'TextKit-Playground-Contacts'
    }
}

export default config; 