import { TextAreaInput } from "./index.styled";

interface AutoGrowProps { 
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
}

const AutoGrowTextArea = ({ placeholder, value, onChange }: AutoGrowProps) => {
    return (
        <TextAreaInput>
            <textarea
                rows={1}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                placeholder={placeholder}
                style={{
                    gridArea: '1 / 1 / 2 / 2',
                    width: '100%',
                    padding: 0,
                    border: 'none',
                }}
            />
            <span
                style={{
                    gridArea: '1 / 1 / 2 / 2',
                    visibility: 'hidden',
                    whiteSpace: 'pre-wrap',
                    lineHeight: '22px',
                }}
            >
            {value}{' '}
            </span>
        </TextAreaInput>
    )
}

export default AutoGrowTextArea;