import IframeResizer from "iframe-resizer-react";

import { ACCOUNT_DETAILS_HEIGHT, ACTIONS_BAR_HEIGHT, TWidgetState } from "../../providers/WidgetProvider";
import { WidgetViewSize } from "../../types";
import { TimelineWrapper, WidgetContent } from "./index.styled";

const TimelineWidget = ({ state, visible = false }: { state: TWidgetState, visible: boolean }) => {
    const maxHeight = window.innerHeight - ACCOUNT_DETAILS_HEIGHT - ACTIONS_BAR_HEIGHT;

    return (
        <TimelineWrapper id={`${state.name}-timeline-widget-wrapper`} visible={visible}>
            <WidgetContent collapsed={state.size === WidgetViewSize.Small} open={false} maxHeight={maxHeight}>
                <IframeResizer
                    name={state.name}
                    title={state.name}
                    id={state.name}
                    frameBorder="0"
                    seamless
                    src={state.url}
                    checkOrigin={false}
                    onMessage={() => {}}
                />
            </WidgetContent>
        </TimelineWrapper>
    );
}

export default TimelineWidget
