import React from "react";
import styled from "styled-components";

interface IconButtonProps {
    disabled?: boolean;
    children: React.ReactNode;
    size?: number;
    style?: React.CSSProperties;
    onClick?: () => void;
}

const Button = styled.button<{ size?: number }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${p => `${p.size}px` ?? 'auto'};
    height: ${p => `${p.size}px` ?? 'auto'};
    color: ${p => p.theme.colors.primary.default};
    background: ${p => p.theme.colors.backgrounds.white};
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;

    &:hover {
        background-color: ${p => p.theme.colors.backgrounds.hover};
    }

    &:disabled {
        background-color: transparent;
        cursor: not-allowed;
    }
`

const IconButton = ({ disabled = false, children, size, style, onClick }: IconButtonProps, ref: any) => {
    return (
        <Button ref={ref} size={size} disabled={disabled} onClick={onClick} style={style}>
            {children}
        </Button>
    )
}

export default React.forwardRef(IconButton);