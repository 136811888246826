import React from "react";
import IframeResizer from "iframe-resizer-react";
import { useTheme } from "styled-components";

import { TWidgetContextState, TWidgetState, WidgetContext } from "../../providers/WidgetProvider";
import { SendableHeader, SendableWrapper, WidgetContent } from "./index.styled";
import CloseIcon from "../../icons/Close";

const SendableWidget = ({ state, visible = false, bottom = 48 }: { state: TWidgetState, visible: boolean, bottom?: number }) => {
    const theme = useTheme();
    const { changeProperty } = React.useContext<TWidgetContextState>(WidgetContext);

    const handleBackButton = () => {
        changeProperty(state.name, 'isOpen', false);
    }

    return (
        <SendableWrapper bottom={bottom} visible={visible}>
            <SendableHeader>
                <h2>{state.label}</h2>
                <button onClick={handleBackButton}>
                    <CloseIcon size={14} color={theme.colors.text.l3} />
                </button>
            </SendableHeader>
            <WidgetContent collapsed={false} open={visible}>
                <IframeResizer
                    name={state.name}
                    title={state.name}
                    id={state.name}
                    frameBorder="0"
                    seamless
                    src={state.url}
                    checkOrigin={false}
                    onMessage={() => {}}
                />
            </WidgetContent>
        </SendableWrapper>
    );
}

export default SendableWidget
