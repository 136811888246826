import * as React from "react";

function Conversations(props: any) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 445.1 340.8"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st0{opacity:.9}.prefix__st1{fill:#f2f2f2}.prefix__st3{fill:#919191}.prefix__st6{fill:#dbdcde}.prefix__st7{opacity:.2;enable-background:new}.prefix__st8{fill:#cecece}.prefix__st9{fill:#aeb5c1}.prefix__st13{fill:#c6c9cc}.prefix__st14{opacity:.7;fill:#4e66d0;enable-background:new}.prefix__st15{fill:#fff}.prefix__st16,.prefix__st17{opacity:.15;fill:#c2c6cc;enable-background:new}.prefix__st17{opacity:.5}"
        }
      </style>
      <g id="prefix__Background_Simple">
        <g className="prefix__st0">
          <path
            className="prefix__st1"
            d="M14.8 71.8c2.6 1.2 5.7.7 7.9-1.4 20.6-20.5 47.8-34.2 76.5-38.7 3.9-.6 6.5-4.2 5.9-8.1s-4.2-6.5-8.1-5.9c-31.6 4.9-61.6 20.1-84.3 42.6-2.8 2.8-2.8 7.2 0 10 .6.6 1.3 1.1 2.1 1.5z"
          />
        </g>
        <g className="prefix__st0">
          <path
            className="prefix__st1"
            d="M432.5 251.1c-6.6 26.9-28 51.2-55.3 55.5-30.6 4.8-59-14.7-84.9-31.5-50.1-32.4-104.6-57.9-161.5-76-26.6-8.4-56.5-12.3-80.8-27.5-27.8-17.3-41.2-53.4-26-83.8C46.1 43.6 105.6 33.7 150 31c60.1-3.6 120.3 8.4 173.9 36 65.6 33.9 127.9 104.5 108.6 184.1z"
          />
        </g>
      </g>
      <g id="prefix__Shadow_1_">
        <ellipse
          id="prefix___x3C_Path_x3E__1_"
          cx={234.6}
          cy={316.5}
          rx={193.9}
          ry={11.3}
          fill="#f5f5f5"
        />
      </g>
      <g id="prefix__Smartphone">
        <path
          className="prefix__st3"
          d="M285.9 11.2h-138c-6.5 0-11.9 5.3-11.9 11.9v281.5c0 6.5 5.3 11.9 11.9 11.9h138c6.5 0 11.9-5.3 11.9-11.9V23c0-6.5-5.4-11.8-11.9-11.8z"
        />
        <path
          d="M147.1 311.8c-4 0-7.3-3.3-7.3-7.3V23c0-4 3.3-7.3 7.3-7.3h138c4 0 7.3 3.3 7.3 7.3v281.5c0 4-3.3 7.3-7.3 7.3h-138z"
          opacity={0.95}
          fill="#fff"
        />
        <circle cx={216.1} cy={292.3} r={8.2} fill="#74767a" />
        <path
          className="prefix__st3"
          d="M209.8 14.9c0 3.8 3 6.8 6.8 6.8s6.8-3 6.8-6.8h-13.6z"
        />
      </g>
      <g id="prefix__Character_2">
        <path
          className="prefix__st6"
          d="M104.9 235.6c-1.1 2.2-2.1 4.4-3.1 6.6-1 2.2-2 4.4-2.9 6.7-.9 2.2-1.9 4.5-2.8 6.8-.9 2.3-1.8 4.6-2.6 7l3.6-3.4c-1.7.7-4 1-6.5 1.1-2.4.1-5-.1-7.7-.4-2.6-.3-5.3-.7-8-1.2s-5.4-1.1-8-1.7l-2.4 5.8c2.7 1.5 5.3 2.7 8.1 3.8 2.7 1.1 5.6 2 8.5 2.8 2.9.7 6 1.4 9.2 1.6 3.2.3 6.6.2 10.3-.8l2.3-.6 1.2-2.7c1.9-4.2 4-8.5 6-12.8 1-2.2 2-4.3 3-6.5s2-4.4 3-6.5l-11.2-5.6z"
        />
        <path
          className="prefix__st6"
          d="M70.2 258.8l-3.9-3.8-6.2 6.8s2.3 3.9 7.9 1.6l2.2-4.6zM60.3 252.4l-3.4 5.6 3.1 3.7 6.3-6.7z"
        />
        <path
          className="prefix__st1"
          d="M122.5 226.6c-8-3.1-14.2 1.5-24.3 14.3l11.8 12.2c.1-.1 28.1-20.6 12.5-26.5z"
        />
        <path
          className="prefix__st7"
          d="M107.5 239.8c3.8-2.5 7.1-1.8 9.9.5-1.1 3.9-3.7 8.3-6.3 12-.6.5-1 .7-1 .7l-6.6-6.8c.5-3 1.9-5.1 4-6.4z"
        />
        <path
          className="prefix__st6"
          d="M61.6 311.4l-9.6-1.1 2.8-19.9 7.6 1.3z"
        />
        <path
          className="prefix__st8"
          d="M51.9 306.1l10.8.2c.4 0 .6.3.7.7l1.2 8.5c.1.9-.5 1.7-1.3 1.7-3.4-.1-4.9-.4-9.1-.4-2.6 0-10.5.1-14.1.1-3.5-.1-3.8-4-2.4-4.3 6.7-1.5 10.5-3.7 12.8-5.7.4-.6.9-.8 1.4-.8z"
        />
        <path
          className="prefix__st7"
          d="M62.4 291.8l-7.1-1.3-2.3 12.2 8.8 3z"
        />
        <path
          className="prefix__st9"
          d="M109.4 280.3s-24.9-23.1-41-23.5c-11.8-.3-16 43.2-16 43.2l10.8 4.1s1.5-22.2 10.6-26c13.2 19.8 67.1 59 62 16.5-8-2.8-26.4-14.3-26.4-14.3z"
        />
        <path
          className="prefix__st1"
          d="M142 296.3s10.7-20.9 12-54.5c.3-7.9-6.8-15.4-15-15.8-6.9-.4-14.1-.4-20-.4s-10.4 3.9-11.1 9.6c-1.5 12.5-1.7 31.4-9.4 54l43.5 7.1z"
        />
        <path
          className="prefix__st7"
          d="M149 242c-5.9-6.7-7.9-4.5-8.8-3.7-8.2 7.4 3.8 30.4 6.2 33.8 1.5-7.5 2.7-16.2 3-26.1.1-1.4-.1-2.7-.4-4z"
        />
        <path
          className="prefix__st6"
          d="M131 206c.7 6.1 2.3 15.5 6.5 19.9-3.3 3.1-8.6 6.1-16.2 7.4-3.9-1.6-4.4-5.2-2.5-7.7 6.7-1.2 3-3.9.5-8.1L131 206z"
        />
        <path
          className="prefix__st7"
          d="M127.9 215.3l-8.2 2.9c.6 1 2.2 2.9 2.4 3.9 2.4-1 3.3-2.2 4.9-4.8.7-1.2 1.3-1.5.9-2z"
        />
        <path
          className="prefix__st9"
          d="M113.5 193.1c-3.9 4.2-9.5 5.3-8.6-.8 1.3-8.7 11.6-2.5 8.6.8z"
        />
        <path
          className="prefix__st6"
          d="M129.4 194.2c2.2 8 3.9 12.5 1.4 18-3.7 8.3-13.1 7.4-19.6.8-5.3-5.4-6.2-17.6.2-23.7 6.3-5.9 15.8-3 18 4.9z"
        />
        <path
          className="prefix__st9"
          d="M131.2 201.6c-1-.2-5.9-1.7-8.4-4-2.7-2.6-2.7-5.1-2-6.3.8-1.2 6.5-3.4 9.1-2.8 4.2.8 3.2 8.3 3.1 10.3 0 2-1.8 2.8-1.8 2.8z"
        />
        <path
          className="prefix__st9"
          d="M130 197.8c-11.4.1-18.4-1-23.2-4.4-3.4-2.4-1.2-6.9 2.7-10.1 3.3-2.7 10.7-5.1 12.1-7.3.7.7-.8 1.7-.8 1.7s2.3-.9 8.3-.1c2.7.7 4.3 3 5.8 4.8.7.8 1.3 4.2 1.9 5.1 1.2 1.9-.2 20.9-5.1 23-2.1-4.3 4.2-12.7-1.7-12.7z"
        />
        <g>
          <path
            className="prefix__st6"
            d="M28.4 316.1l-5.7-8.3L38 294.4l5.7 8.3z"
          />
          <path
            className="prefix__st8"
            d="M26.6 303.8l6.7 8.3c.2.3.2.7 0 1l-5.2 6.5c-.5.7-1.6.7-2.1 0-2.1-2.7-3.5-4.8-6.2-8.2-1.7-2.1-6.9-8.2-9.2-11s.3-5.6 1.5-4.7c5.4 4.3 10.3 6.9 13.2 7.3.5.2 1 .4 1.3.8z"
          />
          <path
            className="prefix__st7"
            d="M43.7 302.7l-5.7-8.3-8.8 7.7 6 8.1z"
          />
          <path
            className="prefix__st9"
            d="M126.4 293.2S82.7 248.8 63 267.8c-12.3 11.8-33.6 31.7-33.6 31.7l8.4 8.8s31.9-16.3 38.8-24.1c36.1 40.4 68.4 43.1 65.5 12-8.5-2.1-15.7-3-15.7-3z"
          />
        </g>
        <g>
          <path
            className="prefix__st6"
            d="M141.9 239l-.9 18.2c-.2 3-.3 6-.6 9-.1 1.5-.3 2.9-.4 4.3-.1.7-.2 1.4-.3 2s-.2 1.2-.3 1.5v.2c0-.1.1-.1.1-.2.1-.2.3-.4.5-.5.2-.2.4-.2.5-.3h.1c-.2.1-1.3.1-2.4-.2-2.3-.5-5.1-1.5-7.7-2.7-2.7-1.2-5.3-2.5-8-3.9s-5.3-2.9-7.9-4.3l-3.7 5c4.7 4.3 9.6 8.2 15.2 11.7 1.4.9 2.8 1.7 4.4 2.4 1.5.8 3.1 1.5 4.9 2.1.9.3 1.9.6 2.9.7 1.1.2 2.3.3 3.7.2.7-.1 1.5-.2 2.4-.5.8-.3 1.7-.8 2.6-1.4.8-.6 1.5-1.4 2.1-2.2.3-.4.5-.8.7-1.2l.4-.9c.4-1.1.7-2 .9-2.9s.4-1.7.6-2.5c.3-1.7.6-3.3.8-4.9.5-3.2.8-6.3 1.1-9.4.6-6.3.9-12.5 1-18.7l-12.7-.6z"
          />
          <path
            className="prefix__st1"
            d="M144.9 227.2c4.7.5 11.8 5 12 25.8l-20.2-1.6s-9.5-26.2 8.2-24.2z"
          />
          <path
            className="prefix__st9"
            d="M97.7 251.5l7.5.5c.8.1 1.7.8 1.9 1.7l3.5 16.5c.2.9-.3 1.5-1.2 1.5l-7.5-.5c-.8-.1-1.7-.8-1.9-1.7L96.5 253c-.2-.9.3-1.6 1.2-1.5z"
          />
          <path
            className="prefix__st6"
            d="M114 261.6l-6.2-2.2 1.5 9.7s6.9 1.5 7.5-2.2l-1-3.3c-.3-.9-1-1.7-1.8-2z"
          />
          <path
            className="prefix__st6"
            d="M100.7 260.3l2.7 8.7 6 .2-1.6-9.7z"
          />
        </g>
        <path
          className="prefix__st6"
          d="M133.5 202.4c-.8 1.9-.3 3.5-1.8 4.1-2.3.9-4.8-1.2-4.3-3.5.5-2.1.8-5.8 3.2-5.7 2.3 0 3.9 3 2.9 5.1z"
        />
      </g>
      <g id="prefix__Character_1">
        <path
          className="prefix__st9"
          d="M328.6 59.6c4.9-11.3-21.7-23.2-29.3-5.6-12.8 5.6 3.6 29.2-.1 33.2s-6.5-.9-6.5-.9-3.7 9.9 5.8 17.9c8.5 7.2 34.2 9.1 34.2-5 .1-18.2-10.5-24.7-4.1-39.6z"
        />
        <path fill="#ff8b7b" d="M304.2 297.5l7.1 4.2 15.6-20.9-7.3-6.8z" />
        <path
          className="prefix__st9"
          d="M312.1 299.9l-7.4-5.7c-.3-.2-.6-.2-.8 0L299 299c-.5.5-.4 1.4.2 1.9 2.6 1.9 4 2.7 7.2 5.2 2 1.5 6 4.9 8.7 7s4.8-.3 3.8-1.4c-4.4-5-5.3-8-6-10.7-.1-.4-.4-.8-.8-1.1z"
        />
        <path
          d="M311.8 301v-.2c.1-.1 1.5-1.6 2.8-1.6.4 0 .8.1 1.1.4.4.4.4.7.3.9-.4.8-2.9.9-4 .7 0-.1-.1-.1-.2-.2.1.1.1 0 0 0zm2.9-1.4c-.9 0-1.8.8-2.3 1.3 1.1.2 3-.1 3.2-.6 0 0 .1-.2-.2-.4l-.1-.1c-.1-.2-.4-.2-.6-.2z"
          fill="#407bff"
        />
        <path
          className="prefix__st1"
          d="M330.2 100.8c.5 2.1 1 4.1 1.6 6.1s1.2 4 1.9 5.9c1.4 3.9 3 7.8 4.9 11.5l1.5 2.8c.5.9 1 1.8 1.6 2.8l.9 1.4c.3.5.5.9.9 1.4.2.3.4.5.7.8.3.3.5.6 1.1 1.1.4.2.7.5 1.7.8.2.1.6.1.9.1s.7 0 1.1-.2c.7-.2 1.4-.7 1.7-1 .7-.8.9-1.4 1-1.7.1-.4.2-.7.2-.9.1-1 0-1.5 0-2.2-.1-.6-.2-1.2-.3-1.7-.2-1.1-.5-2.1-.7-3.1-.6-2-1.2-4-2-5.9l-3.9.9.3 6c0 1 .1 1.9.1 2.9v1.3c0 .4-.1.8-.1.8v-.2c0-.1.1-.4.6-1 .2-.3.8-.7 1.4-.8.3-.1.6-.1.9-.1s.6 0 .7.1c.8.2.9.3 1 .5.2.2.1.1.1 0l-.2-.4-1.3-2.4c-.5-.8-.9-1.7-1.3-2.5l-1.2-2.6c-1.6-3.5-3-7.2-4.2-10.9-.6-1.9-1.2-3.7-1.8-5.6-.6-1.9-1.1-3.8-1.5-5.6l-8.3 1.6z"
        />
        <path
          className="prefix__st1"
          d="M333.6 90.3c2.9.5 7.5 18.9 7.5 18.9l-6.7 5.1s-6.7-13.3-7-19.3c-.2-4.3 4-5 6.2-4.7zM344.1 120.5l1.1-9.2 5.2 7.7s-.7 3.2-3.9 3.7l-2.4-2.2zM307.4 313.1l8 .3 1.5-28-11.1.2z"
        />
        <path
          className="prefix__st9"
          d="M315.9 310.8l-9-.1c-.3 0-.6.2-.6.5l-.5 7.1c-.1.7.6 1.4 1.4 1.4 3.1 0 4.6-.2 8.6-.2 2.4 0 6 .3 9.3.3s3.3-3.3 1.9-3.6c-6.3-1.4-7.5-3.3-9.7-5-.4-.2-.9-.4-1.4-.4z"
        />
        <path
          className="prefix__st6"
          d="M334.1 90.3s4 1 4 48.3c-12.7 1.1-25.9 2.3-32.1 2.8-1.6-13.2-2.9-21.3-13.5-47.7 0 0 6.5-2.1 13.3-3 5.4-.7 9.8-1.3 14.6-1.3 6.4.1 13.7.9 13.7.9z"
        />
        <path
          className="prefix__st7"
          d="M298.8 99.8l-1.7 5.8c1.3 3.6 2.5 6.8 3.4 9.7 1-5.4-.4-12.4-1.7-15.5z"
        />
        <path className="prefix__st1" d="M345.2 111.4l5.3 7.7-6.4-.4-3.4-7z" />
        <path
          d="M341.9 129.1l-6-.1c-.8 0-1.5-.8-1.4-1.6l1.9-13.5c.1-.7.7-1.2 1.4-1.2l6 .1c.8 0 1.5.8 1.4 1.6l-1.9 13.5c-.1.7-.7 1.2-1.4 1.2z"
          fill="#e1e7ea"
        />
        <path
          className="prefix__st1"
          d="M304.6 74.1c1.7 4.6 4 13.2 1.2 16.6 0 0 6 5.4 15.9 9.7 3.3-7.8-1.3-10.9-1.3-10.9-5.4-.8-5.9-4.7-5.6-8.5l-10.2-6.9z"
        />
        <path
          className="prefix__st9"
          d="M326.6 68.8c.8 13.9 9.2 17.9 7.2 25-2.8 10-14.1 2.2-14.1 2.2s6.1 2 6.5-2.9c.3-4-13.9-3.4-9.9-12.8 2.6-6.1 10.3-11.5 10.3-11.5z"
        />
        <path
          className="prefix__st7"
          d="M307.5 77.6l7.3 3.4c-.1.9-.1 1.8 0 2.6-1.5.1-5.5-1-6.9-3.5-.7-.9-.5-2-.4-2.5z"
        />
        <path
          className="prefix__st1"
          d="M303.3 61c-.7 7.9-1.2 11.2 2.1 15.8 5 7 14.8 6.1 17.9-1.4 2.8-6.8 3.5-18.5-3.7-22.8-7-4.2-15.5.5-16.3 8.4z"
        />
        <path
          className="prefix__st9"
          d="M305.1 53.1c6.2-4.3 11.4 3.9.3 11.9-11 8-7.4-7-.3-11.9z"
        />
        <path
          className="prefix__st9"
          d="M308.4 50.8c-2.6 2.3 12.7 10.8 21 8s1.6-9.2-1.4-10c2.6 2.5-1.9 6.3-4.2 3.9-2.1-2.3-10.3-6.5-15.4-1.9z"
        />
        <path
          className="prefix__st13"
          d="M338.1 138.6s16.9 71.7 15.4 89.8c-1.7 21.2-38.6 70.2-38.6 70.2l-8.4-5.4s22-47.1 29.8-65.3c-2.5-18.2-24.6-69.6-24.6-86.9l26.4-2.4z"
        />
        <path
          className="prefix__st7"
          d="M325.6 170.2c9.3 12.9 9.8 36.7 9.2 50-3.2-12.3-9.9-30.6-15.3-47.1.2-6.3 1.7-9 6.1-2.9z"
        />
        <path
          className="prefix__st13"
          d="M332.1 139.2s-6.2 61.4-8.3 82.3c-2.5 24.8-7.8 85.9-7.8 85.9l-10 1.1s-2.9-56.6-.9-78.8c-.4-29.5-4.7-69.6.9-88.2l26.1-2.3z"
        />
        <path
          className="prefix__st1"
          d="M301.1 66.4c.1 1.6.9 3.1 2 4 1.5 1.3 3.2.2 3.5-1.7.3-1.7-.2-4.5-2.1-5.1-1.9-.6-3.5.9-3.4 2.8zM290.4 103.3c-.2 1.4-.2 2.7-.3 4.1 0 1.3-.1 2.7-.1 4 0 2.7.2 5.3.5 8s.8 5.3 1.5 8c.4 1.3.8 2.7 1.3 4 .2.7.5 1.3.8 2 0 0 .1.3.2.5s.2.4.4.6c.3.4.6.7.9 1 .6.5 1.2.9 1.8 1.1 1.1.5 2 .6 2.8.7.8.1 1.6.1 2.4.1 1.5 0 2.9-.2 4.2-.4 2.7-.4 5.3-1 7.8-1.7s5-1.5 7.4-2.3c2.4-.9 4.8-1.7 7.3-2.7l-1.2-3.8c-2.4.5-4.9 1.1-7.4 1.6s-4.9 1-7.4 1.4c-2.4.4-4.9.7-7.2.9-1.2.1-2.3.1-3.4.1-.5 0-1-.1-1.4-.2-.4-.1-.8-.2-.8-.3l.2.1.2.2.1.2s.1.2 0 .1c-.2-.5-.4-1-.5-1.6l-.9-3.3c-.5-2.3-.8-4.6-1-7s-.3-4.8-.3-7.2v-3.6c0-1.2.1-2.5.2-3.5l-8.1-1.1z"
        />
        <path
          className="prefix__st1"
          d="M292.5 93.7c-3.5 2.3-2.3 21.9-2.3 21.9l8.1 1.6s.1-10 .9-16.5c.7-4.4-4.2-8.6-6.7-7zM326.8 126.8l5-6.7 2.1 8s-3.7 3.3-6.9 2l-.2-3.3z"
        />
        <path
          className="prefix__st1"
          d="M338.2 118.7l1.3 6.7-6.7 3.4-1.1-8.7z"
        />
      </g>
      <g id="prefix__Messages">
        <path
          className="prefix__st14"
          d="M261.3 44.5h-89c-7.5 0-13.7 6.2-13.7 13.7v9.1c0 7.5 6.2 13.7 13.7 13.7h89l13.7 8.1v-31c0-7.5-6.1-13.6-13.7-13.6z"
        />
        <path
          className="prefix__st15"
          d="M174.8 57.5h84.1v2.3h-84.1zM191 65.6h67.9v2.3H191z"
        />
        <path
          className="prefix__st16"
          d="M172.3 105.3h89c7.5 0 13.7 6.2 13.7 13.7v9.1c0 7.5-6.2 13.7-13.7 13.7h-89l-13.7 8.1v-31c0-7.4 6.2-13.6 13.7-13.6z"
        />
        <path
          className="prefix__st17"
          d="M174.8 118.4h84.1v2.3h-84.1zM174.8 126.5h39v2.3h-39zM219.9 126.5h15.9v2.3h-15.9z"
        />
        <g>
          <path
            className="prefix__st14"
            d="M261.3 166.1h-89c-7.5 0-13.7 6.2-13.7 13.7v9.1c0 7.5 6.2 13.7 13.7 13.7h89l13.7 8.1v-31c0-7.4-6.1-13.6-13.7-13.6z"
          />
          <path
            className="prefix__st15"
            d="M174.8 179.2h84.1v2.3h-84.1zM191 187.3h67.9v2.3H191z"
          />
        </g>
        <g>
          <path
            className="prefix__st16"
            d="M172.3 227h34.6c7.5 0 13.7 6.2 13.7 13.7v9.1c0 7.5-6.2 13.7-13.7 13.7h-34.6l-13.7 8.1v-31c0-7.5 6.2-13.6 13.7-13.6z"
          />
          <path
            className="prefix__st17"
            d="M179.2 245.2c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4 4 1.8 4 4z"
          />
          <circle className="prefix__st17" cx={187.3} cy={242} r={4} />
          <path
            className="prefix__st17"
            d="M203.5 250.1c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4 4 1.8 4 4z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Conversations;
